import React from "react";
import "./Header.css";
import "./Dropdown/Dropdown.css";
import { NavLink, Link, useLocation } from "react-router-dom";
import { Environments } from "src/Environments/Environments";

import { Dropdown } from "./Dropdown/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable, faProjectDiagram, faClipboardList, faChartPie, faShapes, faBell, faBars, faCoins, faUsers, faScroll, faCalculator, faPuzzlePiece, faCalendarAlt, faNetworkWired, faTasks, faSignOutAlt, faSignInAlt, faUserCog, faFolderOpen, faTasksAlt, faSun, faMoon, faHandHoldingDollar, faChartLine, faLanguage, faCheck, faServer } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { LanguageContext } from "src/Components/languageContext/LanguageContext";

function HeaderUI({ sidebarDeploy, setSidebarDeploy, appsOptionsIcon, notificationCount, HeaderAppsOptionToggleColor, user, deployAppsOptions, deployProcessOptions, notifications, darkTheme, thisUserFunctions, toggleTheme, selectedLanguage, setSelectedLanguage, setNotificationToCheck }) {
  
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const { t } = React.useContext(LanguageContext);

  const renderNotifications = () => {
    const notifications_ = notifications.filter(item => !item.message == "");
    return notifications_.map((item, _) => (
      <div className="header-notification" key={_}>
        <div className="notification-title">
          {!item.seen && <FontAwesomeIcon icon={faBell} />} <span><b>{item.title + " "}</b></span><br/>
          <span className='little'>{item.message}</span>
        </div>
        <button disabled={item.seen} type="button" className="btn btn-small btn-green" onClick={() => setNotificationToCheck(item._id)}>
          <FontAwesomeIcon className="btn-icon" icon={faCheck} /> { !item.seen ? t('header.marcarcomoleida') : t('words.leida') }
        </button>
      </div>
    ));
  };

  return (
    <header id='my-header'>
      <button
        type='button'
        className='btn-sidebar'
        onClick={() => setSidebarDeploy(!sidebarDeploy)}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      <Link to='/' className='logo-ibisa'>
        <img src={`/img/${darkTheme ? "logoDark" : "logo"}.png`} alt='Ibisa' />
      </Link>
      {/* <div className="search-container">
        <input
          type="text"
          className="input-search"
          placeholder="Buscar publicaciones, documentos y más"
        />
        <span className="input-search-icon">
          <FontAwesomeIcon icon={faSearch} />
        </span>
      </div> */}
      <div className='ibisa-tools-container'>
        <Dropdown
          id='DropdownProcess'
          key='DropdownProcess'
          className={`ibisa-tool ${useLocation().pathname.includes("procesos")
            ? "ibisa-tool--selected"
            : ""
            }`}
          onClick={deployProcessOptions}
          title={
            <>
              <span className='ibisa-tool-icon'>
                <FontAwesomeIcon icon={faNetworkWired} />
              </span>
              <span className='ibisa-tool-text'>{t('words.procesos')}</span>
            </>
          }>
          <Link to='/procesos' className='dropdown-item'>
            <FontAwesomeIcon icon={faTasks} className='dropdown-item-icon' />
            <span className='dropdown-item-text'>{t('words.procesos')}</span>
          </Link>
          <Link to='/procesos/modelador' className='dropdown-item'>
            <FontAwesomeIcon
              icon={faProjectDiagram}
              className='dropdown-item-icon'
            />
            <span className='dropdown-item-text'>{t('words.modelador')}</span>
          </Link>
          <Link to='/procesos/guardados' className='dropdown-item'>
            <FontAwesomeIcon icon={faUserCog} className='dropdown-item-icon' />
            <span className='dropdown-item-text'>{t('header.misprocesos')}</span>
          </Link>
          <Link to='/procesos/desplegados' className='dropdown-item'>
            <FontAwesomeIcon
              icon={faFolderOpen}
              className='dropdown-item-icon'
            />
            <span className='dropdown-item-text'>{t('header.misdespliegues')}</span>
          </Link>
        </Dropdown>
        <NavLink
          to='/tablas'
          className={({ isActive }) =>
            `ibisa-tool ${isActive ? "ibisa-tool--selected" : ""}`
          }>
          <span className='ibisa-tool-icon'>
            <FontAwesomeIcon icon={faTable} />
          </span>
          <span className='ibisa-tool-text'>{t('words.tablas')}</span>
        </NavLink>
        <NavLink
          to='/tareas'
          className={({ isActive }) =>
            `ibisa-tool ${isActive ? "ibisa-tool--selected" : ""}`
          }>
          <span className='ibisa-tool-icon'>
            <FontAwesomeIcon icon={faClipboardList} />
          </span>
          <span className='ibisa-tool-text'>{t('words.tareas')}</span>
        </NavLink>
        <NavLink
          to='/reportes'
          className={({ isActive }) =>
            `ibisa-tool ${isActive ? "ibisa-tool--selected" : ""}`
          }>
          <span className='ibisa-tool-icon'>
            <FontAwesomeIcon icon={faChartPie} />
          </span>
          <span className='ibisa-tool-text'>{t('words.reportes')}</span>
        </NavLink>
        <NavLink
          to='forms'
          className={({ isActive }) =>
            `ibisa-tool ${isActive ? "ibisa-tool--selected" : ""}`
          }>
          <span className='ibisa-tool-icon'>
            <FontAwesomeIcon icon={faScroll} />
          </span>
          <span className='ibisa-tool-text'>{t('words.formularios')}</span>
        </NavLink>
      </div>
      <div className='right-ibisa-options'>
        <Dropdown
          id='DropdownApps'
          key='DropdownApps'
          className={HeaderAppsOptionToggleColor}
          onActive={deployAppsOptions}
          onDisable={deployAppsOptions}
          title={
            <>
              <span className='ibisa-apps-icon-left'>
                <FontAwesomeIcon icon={faShapes} />
              </span>
              <div className='ibisa-apps-text'>{t('words.aplicaciones')}</div>
              <span className='ibisa-apps-icon-right'>
                <FontAwesomeIcon icon={appsOptionsIcon} />
              </span>
            </>
          }>
          <Link to='apps/links' className='dropdown-item'>
            <FontAwesomeIcon icon={faServer} className='dropdown-item-icon' />
            <span className='dropdown-item-text'>{t('words.enlaces')}</span>
          </Link>
          <Link to='apps/things' className='dropdown-item'>
            <FontAwesomeIcon icon={faCoins} className='dropdown-item-icon' />
            <span className='dropdown-item-text'>{t('words.activos')}</span>
          </Link>
          <Link to='apps/users' className='dropdown-item'>
            <FontAwesomeIcon icon={faUsers} className='dropdown-item-icon' />
            <span className='dropdown-item-text'>{t('words.personas')}</span>
          </Link>
          {/* <Link to='apps/newton' className='dropdown-item'>
            <FontAwesomeIcon
              icon={faCalculator}
              className='dropdown-item-icon'
            />
            <span className='dropdown-item-text'>{t('words.calculos')}</span>
          </Link>
          <Link to='apps/integrator' className='dropdown-item'>
            <FontAwesomeIcon
              icon={faPuzzlePiece}
              className='dropdown-item-icon'
            />
            <span className='dropdown-item-text'>{t('words.integraciones')}</span>
          </Link>
          <Link to='apps/streams' className='dropdown-item'>
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className='dropdown-item-icon'
            />
            <span className='dropdown-item-text'>{t('words.eventos')}</span>
          </Link> */}
          <Link to='apps/archives' className='dropdown-item'>
            <FontAwesomeIcon
              icon={faFolderOpen}
              className='dropdown-item-icon'
            />
            <span className='dropdown-item-text'>{t('words.archivos')}</span>
          </Link>
          {thisUserFunctions.includes("superuser") && (
            <Link to='apps/reassign-tasks' className='dropdown-item'>
              <FontAwesomeIcon
                icon={faTasksAlt}
                className='dropdown-item-icon'
              />
              <span className='dropdown-item-text'>{t('header.reasignartareas')}</span>
            </Link>
          )}
          {/* <Link to='apps/creditos' className='dropdown-item'>
            <FontAwesomeIcon
              icon={faHandHoldingDollar}
              className='dropdown-item-icon'
            />
            <span className='dropdown-item-text'>{t('words.creditos')}</span>
          </Link> */}
          <a
            // onClick={reditectToPaper}
            href="https://paper.ibisagroup.com"
            target="_blank"
            className='dropdown-item'
          >
            <FontAwesomeIcon
              icon={faChartLine}
              className='dropdown-item-icon'
            />
            <span className='dropdown-item-text'>{t('header.tablerosdecontrol')}</span>
          </a>
        </Dropdown>
        <Dropdown
          id='DropdownNotifications'
          key='DropdownNotifications'
          className='notification-icon-container'
          title={
            <>
              <span className='notification-icon'>
                <FontAwesomeIcon icon={faBell} />
                {(notificationCount > 0) && (
                  <span className='notification-count'>
                    {notificationCount > 9 ? "+9" : notificationCount}
                  </span>
                )}
              </span>
            </>
          }>
          {renderNotifications()}
        </Dropdown>
        <Dropdown
          id='DropdownProfile'
          key='DropdownProfile'
          className='profile-container'
          width={280}
          title={
            <>
              <div className='profile-text'>
                <span className='profile-name'>
                  {user.name || user.nickname}
                </span>
                <span className='profile-tenant'>
                  {user["https://claims.ibisa.co/tenant"]?.[0]}
                </span>
              </div>
              <img className='profile-img' src={user.picture} alt='Perfil' onError={e => {e.target.onerror=null; e.target.src='/img/default-user.png'}} />
            </>
          }>
          <span className='dropdown-item'>
            <FontAwesomeIcon
              icon={faLanguage}
              className='dropdown-item-icon'
            />
            <span className='dropdown-item-text'>
              {t('words.idioma')}
            </span>
            <div className='dropdown-item-content languages-btns-container'>
              <img
                src="/img/spain_icon.png"
                className={`language-icon ${selectedLanguage === "es" ? "language-icon-selected" : ""}`}
                alt="Español"
                title="Español"
                onClick={() => setSelectedLanguage("es")}
              />
              <img
                src="/img/usa_icon.png"
                className={`language-icon ${selectedLanguage === "en" ? "language-icon-selected" : ""}`}
                alt="English"
                title="English"
                onClick={() => setSelectedLanguage("en")}
              />
              <img
                src="/img/brazil_icon.png"
                className={`language-icon ${selectedLanguage === "pt" ? "language-icon-selected" : ""}`}
                alt="Português"
                title="Português"
                onClick={() => setSelectedLanguage("pt")}
              />
            </div>
          </span>
          <span onClick={toggleTheme} className='dropdown-item'>
            <FontAwesomeIcon
              icon={!darkTheme ? faMoon : faSun}
              className='dropdown-item-icon'
            />
            <span className='dropdown-item-text'>
              {!darkTheme ? t('header.temaoscuro') : t('header.temaclaro')}
            </span>
          </span>
          {isAuthenticated ? (
            <a
              href='#'
              className='dropdown-item'
              onClick={() => logout({ returnTo: Environments.ibisa20 })}>
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className='dropdown-item-icon'
              />
              <span className='dropdown-item-text'>{t('header.cerrarsesion')}</span>
            </a>
          ) : (
            <a href='#' className='dropdown-item' onClick={loginWithRedirect}>
              <FontAwesomeIcon
                icon={faSignInAlt}
                className='dropdown-item-icon'
              />
              <span className='dropdown-item-text'>{t('words.ingresar')}</span>
            </a>
          )}
        </Dropdown>
      </div>
    </header>
  );
}

export { HeaderUI };
