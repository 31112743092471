import React from 'react';
import "./LoadingAlert.css"
import { GlobalContext } from '../../Contexts/GlobalContext/GlobalContext'
import { LoadingScreen } from '../../Screens/LoadingScreen/LoadingScreen'

function LoadingAlert() {
  const { showLoadingAlert } = React.useContext(GlobalContext);
  const [ alertDisplay, setAlertDisplay ] = React.useState(false);
  const count = React.useRef(0);
  
  React.useEffect(() => {
    if (count){
      setAlertDisplay(showLoadingAlert);
    }
    count.current += 1;
  }, [ showLoadingAlert ])

  return (
    <div className={
      `loading-alert-container loading-alert-display-${alertDisplay}`
    }>
      <LoadingScreen />
    </div>
  )
}

export { LoadingAlert }