import React, { useContext, useState } from 'react';
import './App.css';
import { ComposeRoute as Routing } from './Routes/ComposeRoute';

// QUERY
import { QueryClient, QueryClientProvider } from 'react-query';
import { AlertProvider } from './Contexts/AlertContext/AlertContext';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className='root-content'>
        <AlertProvider>
          <Routing />
        </AlertProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </div>
    </QueryClientProvider>
  );
}

export default App;
