import React, { useContext, useRef, useState } from 'react';
import { Envi, Environments } from '../../Environments/Environments';
import { useAPI } from '../../Hooks/useAPI/useAPI';
import { useAuth0 } from "@auth0/auth0-react"
import { themes } from './Utils';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FetchAPI } from '../../Services/API/fetchAPI'
import { AlertContext } from '../AlertContext/AlertContext';
import { LoadingScreen } from 'src/Screens/LoadingScreen/LoadingScreen';

const GlobalContext = React.createContext();

export const useGlobalContext = () => useContext(GlobalContext);

function GlobalProvider({children}) {

  const { isLoading, user: userAuth0, getAccessTokenSilently } = useAuth0();
  const { showAlert } = useContext(AlertContext);
  const [thisUserFunctions, setUserFunctions] = React.useState([]);
  const [thisUser, setThisUser] = React.useState({});
  const [ token, setToken ] = useState(false);

  const [ darkTheme, setDarkTheme ] = React.useState(false);
  const [ activeNotifications, setActiveNotifications ] = useState(false);

  const firstRender = useRef(true);

  const API = FetchAPI();

  const [ showLoadingAlert, setShowLoadingAlert ] = React.useState(false);
  
  const userData = useAPI(Environments.toolbox + 'users/' + userAuth0?.sub, "GET", {}, [isLoading], (!isLoading));
  const camundaURL = useAPI(Environments.tables + 'configs/getCamundaURL', "GET", {}, [isLoading], (!isLoading));

  const getTinyDate = (date) => (
    `${new Date(date).getDate()} de
    ${new Intl.DateTimeFormat('es-ES', {month: 'long'}).format(new Date(date)).slice(0, 3)} del
    ${new Date(date).getFullYear()}`
  )

  const toggleTheme = () => {
    setDarkTheme(!darkTheme);
  }

  const darkThemeMUI = createTheme({
    palette: {
      mode: darkTheme ? 'dark' : 'light',
    },
  });

  const checkNotifications = () => {
    if (!('Notification' in window)) return showAlert({
      type: 'warning',
      title: 'Notificaciones no disponibles',
      message: 'El navegador no permite notificaciones'
    });

    if (Notification.permission === "granted") return setActiveNotifications(true);

    Notification.requestPermission().then(permission => {
      if (permission === "granted") return setActiveNotifications(true);
      setActiveNotifications(false);
    }).catch(err => console.log(err));
  }

  React.useEffect(() => {
    if (!isLoading) setThisUser({ ...thisUser, tenantAdmin: userAuth0["https://claims.ibisa.co/tenantAdmin"] });
    if (!userData.loading && !userData.error) {
      setUserFunctions([ ...userData.response.data.function ]);
      setThisUser({ ...thisUser, ...userData.response.data });
    }
  }, [userData.loading, isLoading]);

  React.useEffect(() => {
    if (!camundaURL.loading && !camundaURL.error) {
      const url = camundaURL.response.data[0]?.url;
      if (url) Envi.setProcessURL(url);
    }
  }, [camundaURL.loading, isLoading]);

  React.useEffect(() => {
    if (localStorage.getItem("ibisaDarkTheme") === "true") setDarkTheme(true);
    checkNotifications();

    const setAuthToken = async () => {
      const newToken = await getAccessTokenSilently();
      setToken(newToken);
    }

    setAuthToken();
  }, []);

  React.useEffect(() => {
    if (darkTheme) {
      for (const property of themes.dark) document.documentElement.style.setProperty(property.name, property.value);
      localStorage.setItem('ibisaDarkTheme', 'true');
    } else if (!firstRender.current) {
      for (const property of themes.light) document.documentElement.style.setProperty(property.name, property.value);
      localStorage.removeItem('ibisaDarkTheme');
    }

    firstRender.current = false
  }, [darkTheme]);

  return !camundaURL.loading ? (
    <GlobalContext.Provider
      value={{
        showAlert,
        getTinyDate,
        thisUserFunctions,
        thisUser,
        toggleTheme,
        darkTheme,
        showLoadingAlert,
        setShowLoadingAlert,
        API,
        activeNotifications,
        token
      }}
    >
      <ThemeProvider theme={darkThemeMUI}>
        {children}
      </ThemeProvider>
    </GlobalContext.Provider>
  ) : <LoadingScreen />
}

export { GlobalProvider, GlobalContext };