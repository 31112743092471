import React, { useRef } from 'react';
import axios from 'axios'

import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalContext } from 'src/Contexts/GlobalContext/GlobalContext';

/**
 * Hook para realizar peticiones
 * @param {String} url - URL para la petición
 * @param {String} method - Método de la pedición (GET, POST, PUT, PATCH, DELETE, etc...)
 * @param {Object} body - Payload de la petición
 * @param {Array} dependencies - Dependencias a escuchar para volver a hacer la petición
 * @param {Boolean} condition - Condición para ejecutar la petición
 * @param {String} contentType - Tipo de contenido de la petición
 * @param {String} responseType - Tipo de respuesta de la petición
 * 
 * @return {Object} 
 * ```js
 * {
 *  response: "Respuesta de la petición",
 *  loading: "Si está cargando la petición",
 *  error: "Error de la petición",
 *  reload: "Método para recargar la petición",
 *  unsubscribe: "Método para cancelar la subscripción",
 *  executed: "Si la petición se ha ejecutado",
 *  timesExecuted: "Cantidad de veces ejecutada",
 * }
 * ```
 */

function useAPI(pURL, pMethod, pBody, pDependencies, pCondition = true, pContentType, pResponseType, pCustomHeaders, pShowAlert, pTimeout) {

  const url = (typeof pURL === 'string' ? pURL : false) || pURL?.url || "/";
  const method = pMethod || pURL?.method || "GET";
  const body = pBody || pURL?.body || {};
  const dependencies = pDependencies || pURL?.dependencies || [];
  const { condition } = typeof pURL === 'string' ? { condition: pCondition } : { condition: pCondition, ...pURL };
  const contentType = pContentType || pURL?.contentType || 'application/json';
  const responseType = pResponseType || pURL?.responseType || 'application/json';
  const customHeaders = pCustomHeaders || pURL?.customHeaders || {};
  const showAlert = pShowAlert || pURL?.showAlert;
  const onSuccess = typeof pURL?.onSuccess === 'function' ? pURL?.onSuccess : (() => {});
  const onError = typeof pURL?.onSuccess === 'function' ? pURL?.onSuccess : (() => {});
  const timeout = pTimeout || pURL?.timeout || 120000;

  const globalContext = useGlobalContext();
  
  const [response, setResponse] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [executed, setExecuted] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [token, setToken] = React.useState(null);
  const [timesExecuted, setTimesExecuted] = React.useState(0);
  const [load, setLoad] = React.useState(0);

  const { getAccessTokenSilently, isLoading } = useAuth0();

  const subscribed = useRef(true);
  
  React.useEffect(() => {
    getAccessTokenSilently()
    .then((response) => {
      setToken(response);
    })
    .catch((error) => console.log(error));
  }, []);
  
  React.useEffect(() => {
    if (!isLoading && token && condition) {

      setLoading(true);
      setExecuted(true);
      setError(true);
      
      const config = {
        url: url,
        method: method,
        headers: {
          'Content-Type': contentType,
          Authorization: 'Bearer ' + token,
          ...customHeaders
        },
        responseType: responseType,
        data: body,
        timeout: timeout
      };

      try {
        axios({...config})
        .then(res => {
          if (!subscribed.current) return;

          setResponse(res.data);
          if (res.status !== 200 && res.status !== 201 && res.status !== 204 && res.status !== 203) {
            setError(true);
          }else{
            setError(false);
          }
          setLoading(false);
        })
        .catch(err => {
          if (!subscribed.current) return;

          console.log(err);
          setLoading(false);
          setError(err);
        });
        setTimesExecuted(timesExecuted + 1)
      } catch (err) {
        console.log(err);
        
        if (!subscribed.current) return;

        setLoading(false);
        setError(err);
      }
    }
  }, [token, ...dependencies, load]);

  React.useEffect(() => { if (showAlert) globalContext?.setShowLoadingAlert( Boolean(executed && loading) ) }, [ loading, executed ]);

  React.useEffect(() => { if (showAlert) 
    if (!loading && !error) onSuccess();
    else if (!loading && error) onError();
  }, [ loading ])

  const reload = () => {
    setLoad(load + 1);
  }

  const unsubscribe = () => {
    subscribed.current = false;
  }

  return {
    url,
    response,
    loading,
    error,
    reload,
    unsubscribe,
    executed,
    timesExecuted,
  };
}

export { useAPI };
