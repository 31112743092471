import React, { useMemo } from 'react';
import axios from 'axios';

import { useAuth0 } from "@auth0/auth0-react";
import { GlobalContext } from 'src/Contexts/GlobalContext/GlobalContext';

function FetchAPI() {
  const [token, setToken] = React.useState(null);

  const { getAccessTokenSilently, isLoading } = useAuth0();
	const globalContext = React.useContext(GlobalContext)

  React.useEffect(() => {
    getAccessTokenSilently()
    .then((response) => {
      setToken(response);
    })
    .catch((error) => console.log(error));
  }, []);

	const fetch = useMemo(() => {
		return async function (pURL, pMethod, pBody, pCondition = true, pContentType, pResponseType, pCustomHeaders, pShowAlert, pNotUseToken) {

			const url = (typeof pURL === 'string' ? pURL : false) || pURL?.url || "/";
			const method = pMethod || pURL?.method || "GET";
			const body = pBody || pURL?.body || {};
			const { condition } = typeof pURL === 'string' ? { condition: pCondition } : { condition: pCondition, ...pURL };
			const contentType = pContentType || pURL?.contentType || 'application/json';
			const responseType = pResponseType || pURL?.responseType || 'application/json';
			const customHeaders = pCustomHeaders || pURL?.customHeaders || {};
			const showAlert = pShowAlert || pURL?.showAlert;
			const notUseToken = (pNotUseToken || pURL?.notUseToken) ?? false;
				
			let response = [];
			let error = false;
		
			if (token && condition) {
				error = true;
		
				const config = {
					url: url,
					method: method,
					headers: {
						"Content-Type": contentType,
						...customHeaders,
					},
					responseType: responseType,
					data: body,
				};

				if (!notUseToken) config.headers.Authorization = 'Bearer ' + token;
		
				try {
					if (showAlert) globalContext?.setShowLoadingAlert(true);
					const subRes = await axios({ ...config });
					response = subRes.data;
					if (
						subRes.status !== 200 &&
						subRes.status !== 201 &&
						subRes.status !== 204 &&
						subRes.status !== 203
					) {
						error = true;
					} else {
						error = false;
					}
					if (showAlert) globalContext?.setShowLoadingAlert(false);
				} catch (err) {
					if (showAlert) globalContext?.setShowLoadingAlert(false);
					error = err;
				}
			}
		
			return {
				response,
				error
			};
		}
	}
	, [token])


  return {
    fetch
  };
}

export { FetchAPI };