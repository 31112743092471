import React from 'react';
import { Link } from 'react-router-dom';
import "./NotFound.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faHome } from '@fortawesome/free-solid-svg-icons';

function NotFound() {
  return (
    <div className="notfound-container">
      <div className="notfound-error-container">
        <FontAwesomeIcon className="notfound-error-icon" icon={faExclamationCircle} />
        <p className="notfound-error-text">Página no encontrada</p>
        <Link to='/' className='btn btn-black btn-medium'>
          <FontAwesomeIcon className="btn-icon" icon={faHome} />
          Volver al inicio
        </Link>
      </div>
    </div>
  )
}

export { NotFound };