import es from './langs/es.json';
import en from './langs/en.json';
import pt from './langs/pt.json';

const langs = {
  es: {
    translation: es
  },
  en: {
    translation: en
  },
  pt: {
    translation: pt
  }
}

export { langs };