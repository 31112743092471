import React from 'react';
import "./LoadingScreen.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function LoadingScreen({ message, minified }) {
  if (!minified) return (
    <div className="loading-screen-container">
      <div className="loading-screen-bar">
      </div>
      {(message) ? (
        <div className='loading-screen-message'>{message}</div>
      ) : ("")}
    </div>
  )

  return (
    <div className="loading-screen-minified">
      <FontAwesomeIcon className='loading-icon' icon={faSpinner} />
      {(message) ? (
        <div className='loading-screen-message'>{message}</div>
      ) : ("")}
    </div>
  )
}

export { LoadingScreen };