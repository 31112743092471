import React from 'react';
import './Main.css';
import { Outlet } from 'react-router-dom';
import { Header } from '../Header/Header';
import { Alert } from '../Alert/Alert';
import { LoadingAlert } from '../LoadingAlert/LoadingAlert';

function Main({ children }) {
  return (
    <div id="init-container">
      <Header/>
      <Alert/>
      <LoadingAlert/>
      <main id="my-main">
        {children}
        <Outlet/>
      </main>
    </div>
  )
}

export { Main }