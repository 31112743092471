import React from 'react';
import ReactDOM from 'react-dom';
import './Assets/css/styles.css';
import './Assets/css/fonts.css';
import './index.css';
import App from './App';

import { Auth0Provider } from '@auth0/auth0-react';
import { Environments } from './Environments/Environments';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { LanguageProvider } from './Components/languageContext/LanguageContext';
import './Lang/config';
import { ErrorBoundary } from './Layouts/ErrorBoundary/ErrorBoundary';

ReactDOM.render(
  <React.StrictMode>

    <Auth0Provider
      domain='ibisa.auth0.com'
      clientId='D1On5bayihgFlHAvP7QYrQjsI30soTys'
      authorizeTimeoutInSeconds={50}
      redirectUri={Environments.ibisa20}>
      <ErrorBoundary>
        <LanguageProvider>
          <App />
        </LanguageProvider>
      </ErrorBoundary>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// PWA
serviceWorkerRegistration.register();
reportWebVitals();