import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';

import { t } from 'i18next';
import PrivateRoutes from "./PrivateRoutes";
import { Main } from 'src/Layouts/Main/Main';
import { LoadingScreen } from 'src/Screens/LoadingScreen/LoadingScreen';
import { ErrorBoundary } from 'src/Layouts/ErrorBoundary/ErrorBoundary';
import { NotFound } from 'src/Layouts/NotFound/NotFound';

const Home = lazy(() => import('../Components/home/index'));
const Procesos = lazy(() => import('../Components/procesos/Procesos').then(component => ({ default: component.Procesos })));
const ProcesosProvider = lazy(() => import('../Components/procesos/ProcesosContext').then(component => ({ default: component.ProcesosProvider })));
const Modelador = lazy(() => import('../Components/procesos/modelador/Modelador').then(component => ({ default: component.Modelador })));
const Deployments = lazy(() => import('../Components/procesos/subcomponents/deployments/Deployments').then(component => ({ default: component.Deployments })));
const ProcessSaved = lazy(() => import('../Components/procesos/subcomponents/processSaved/ProcessSaved').then(component => ({ default: component.ProcessSaved })));
const Things = lazy(() => import('../Components/apps/things/Things').then(component => ({ default: component.Things })));
const ThingsProvider = lazy(() => import('../Components/apps/things/ThingsContext').then(component => ({ default: component.ThingsProvider })));
const ThingCreate = lazy(() => import('../Components/apps/things/ThingCreate/ThingCreate').then(component => ({ default: component.ThingCreate })));
const ThingEdit = lazy(() => import('../Components/apps/things/ThingEdit/ThingEdit').then(component => ({ default: component.ThingEdit })));
const Maintenance = lazy(() => import('../Components/apps/things/Maintenance/Maintenance').then(component => ({ default: component.Maintenance })));
const Archives = lazy(() => import('../Components/apps/archives/Archives').then(component => ({ default: component.Archives })));
const ReassignTasks = lazy(() => import('../Components/apps/reassign-task/ReassignTasks').then(component => ({ default: component.ReassignTasks })));
const Table = lazy(() => import('../Components/tables/tables').then(component => ({ default: component.Table })));
const Newton = lazy(() => import('../Components/apps/newton/newton').then(component => ({ default: component.Newton })));
const Credits = lazy(() => import('../Components/apps/credits/credits').then(component => ({ default: component.Credits })));
const CreditsProvider = lazy(() => import('../Components/apps/credits/CreditsContext').then(component => ({ default: component.CreditsProvider })));
const Integrator = lazy(() => import('../Components/apps/integrator/integrator').then(component => ({ default: component.Integrator })));
const Users = lazy(() => import('../Components/apps/users/Users').then(component => ({ default: component.Users })));
const UsersProvider = lazy(() => import('../Components/apps/users/UsersContext').then(component => ({ default: component.UsersProvider })));
const ViewUser = lazy(() => import('../Components/apps/users/ViewUser/ViewUser').then(component => ({ default: component.ViewUser })));
const Tasks = lazy(() => import('../Components/task/Tasks').then(component => ({ default: component.Tasks })));
const Streams = lazy(() => import('../Components/apps/streams/Streams').then(component => ({ default: component.Streams })));
const Forms = lazy(() => import('../Components/forms/Forms').then(component => ({ default: component.Forms })));
const FormsProvider = lazy(() => import('../Components/forms/FormsContext').then(component => ({ default: component.FormsProvider })));
const Reports = lazy(() => import('../Components/reports/Reports').then(component => ({ default: component.Reports })));
const NewForm = lazy(() => import('../Components/forms/subcomponents/NewForm/NewForm').then(component => ({ default: component.NewForm })));
const EditForm = lazy(() => import('../Components/forms/subcomponents/EditForm/EditForm').then(component => ({ default: component.EditForm })));
const Links = lazy(() => import('../Components/apps/links/links').then(component => ({ default: component.Links })));
const PublicForm = lazy(() => import('../Components/public/forms/PublicForm').then(component => ({ default: component.PublicForm })));

const OldTables = lazy(() => import('../Components/tables/old/oldtables').then(component => ({ default: component.Table })));

const OldThings = lazy(() => import('../Components/apps/things/old/Things').then(component => ({ default: component.Things })));
const OldThingsProvider = lazy(() => import('../Components/apps/things/old/ThingsContext').then(component => ({ default: component.ThingsProvider })));
const OldThingCreate = lazy(() => import('../Components/apps/things/old/ThingCreate/ThingCreate').then(component => ({ default: component.ThingCreate })));
const OldThingEdit = lazy(() => import('../Components/apps/things/old/ThingEdit/ThingEdit').then(component => ({ default: component.ThingEdit })));
const OldMaintenance = lazy(() => import('../Components/apps/things/old/Maintenance/Maintenance').then(component => ({ default: component.Maintenance })));

function ComposeRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="public" element={
          <ErrorBoundary>
            <Suspense fallback={<LoadingScreen message={t('words.cargando')} /> } children={<Outlet />} />
          </ErrorBoundary>
        }>
          <Route path="form/:instanceId" element={<PublicForm />} />
        </Route>
        <Route path="" element={<PrivateRoutes />}>
          <Route path="/" element={<Main />}>
            <Route path="" element={
              <ErrorBoundary>
                <Suspense fallback={ <LoadingScreen message={t('words.cargando')} /> } children={<Outlet />} />
              </ErrorBoundary>
            }>
              <Route path="" element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="procesos" element={<ProcesosProvider />}>
                <Route path="" element={<Procesos />} />
                <Route path="modelador" element={<Modelador />} />
                <Route path="modelador/:id" element={<Modelador />} />
                <Route path="guardados" element={<ProcessSaved />} />
                <Route path="desplegados" element={<Deployments />} />
              </Route>
              <Route path="tablas" element={<Table />} />
              <Route path="tareas" element={<Tasks />} />
              <Route path="tareas" element={<br />} />
              <Route path="reportes" element={<Reports />} />
              <Route path='forms' element={<FormsProvider />}>
                <Route path='' element={<Forms />} />
                <Route path='new' element={<NewForm />} />
                <Route path='edit/:formId' element={<EditForm />} />
              </Route>
              <Route path="apps" element={<Outlet />}>
                <Route path="integrator" element={<Integrator />} />
                <Route path="links" element={<Links />} />
                <Route path="old/things" element={<OldThingsProvider />}>
                  <Route path="" element={<OldThings />} />
                  <Route path="create" element={<OldThingCreate />} />
                  <Route path="edit/:thingId" element={<OldThingEdit />} />
                  <Route path="maintenance/:thingId" element={<OldMaintenance />} />
                </Route>
                <Route path="things" element={<ThingsProvider />}>
                  <Route path="" element={<Things />} />
                  <Route path="create" element={<ThingCreate />} />
                  <Route path="edit/:thingId" element={<ThingEdit />} />
                  <Route path="maintenance/:thingId" element={<Maintenance />} />
                </Route>
                <Route path='archives' element={<Archives />} />
                <Route path='reassign-tasks' element={<ReassignTasks />} />
                <Route path='newton' element={<Newton />} />
                <Route path='users' element={<UsersProvider />}>
                  <Route path='' element={<Users />} />
                  <Route path=':userId' element={<ViewUser />} />
                </Route>
                <Route path="creditos" element={<CreditsProvider />}>
                  <Route path="" element={<Credits />} />
                </Route>
                <Route path='streams' element={<Streams />} />
              </Route>
            </Route>

            {/* habilitacion temporal del tablas anterior */}
            <Route path='old' element={<ErrorBoundary>
              <Suspense fallback={ <LoadingScreen message={t('words.cargando')} /> } children={<Outlet />} />
            </ErrorBoundary>}>
              <Route path='tables' element={<OldTables />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export { ComposeRoute };
