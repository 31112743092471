import React from 'react';
import "./ErrorScreen.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faRedoAlt, faSync } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';

function ErrorScreen({ componentsToReload, message, minified }) {

  const reloadComponents = () => {
    componentsToReload.forEach(reload => { if (reload) reload() });
  }

  if (!minified) return (
    <div className="error-screen-container">
      <h1 className="error-screen-title">
        <FontAwesomeIcon icon={faExclamationTriangle} className="error-screen-icon" />
        Oops...!
      </h1>
      <p className="error-screen-text">{ message || t('common.haocurridounerroralintentarcargarlosdatos') }</p>
      {(componentsToReload) && (componentsToReload.length ? (
        <button type="button" className="btn btn-orange btn-medium" onClick={reloadComponents}>
          <FontAwesomeIcon icon={faRedoAlt} className="error-screen-reload-icon" />
          {t('words.recargar')}
        </button>
      ) : <></>)}
    </div>
  )

  return (
    <div className="error-screen-minified">
      <FontAwesomeIcon className='error-icon' icon={faExclamationTriangle} />
      <div className='error-screen-text'>{message || t('common.haocurridounerroralintentarcargarlosdatos')}</div>
      <button type='button' className='btn btn-orange btn-medium' onClick={reloadComponents}>
        <FontAwesomeIcon icon={faSync} />
      </button>
    </div>
  )
}

export { ErrorScreen }