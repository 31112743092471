import { createContext, useContext, useState } from 'react';

export const AlertContext = createContext();

export const useAlertContext = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {

  const [ alertConfig, showAlert ] = useState({
    type: 'success',
    title: '',
    message: '',
  });

  return (
    <AlertContext.Provider
      value={{
        showAlert,
        alertConfig
      }}
    >
      { children }
    </AlertContext.Provider>
  )
}