import { useState, useEffect, createContext } from "react"
import { useTranslation } from 'react-i18next';
import * as DGLanguage from '@mui/x-data-grid';
import moment from "moment";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {

  const { i18n, t } = useTranslation();
  const [ selectedLanguage, setSelectedLanguage ] = useState("en");
  const [ formatedLanguage, setFormatedLanguage ] = useState("enUS");
  const [ DataGridLanguage, setDataGridLanguage ] = useState(DGLanguage.enUS.components.MuiDataGrid.defaultProps.localeText);
  const [ reload, setReload ] = useState(0);

  const avalibleLanguages = [ "es", "en", "pt" ];

  const getFormatedLanguage = (lang, withStripe) => {

    const stripe = withStripe ? "-" : "";

    if (lang === "es") return `es${stripe}ES`;
    if (lang === "en") return `en${stripe}US`;
    if (lang === "pt") return `pt${stripe}BR`;
    return `es${stripe}ES`;
  }

  useEffect(() => {
    try {
      const lang = localStorage.getItem("ibisaLang");
      const userLang = navigator?.language?.slice(0, 2);
  
      if (!lang && avalibleLanguages.includes(userLang)) {
        setSelectedLanguage(userLang);
      } else {
        setSelectedLanguage( lang || "en" );
      }
    } catch (err) {}
  }, []);

  useEffect(() => {
    localStorage.setItem('ibisaLang', selectedLanguage);
    setDataGridLanguage( DGLanguage[getFormatedLanguage(selectedLanguage)]?.components?.MuiDataGrid?.defaultProps?.localeText || DGLanguage.enUS.components.MuiDataGrid.defaultProps.localeText)
    i18n.changeLanguage(selectedLanguage);
    setFormatedLanguage(getFormatedLanguage(selectedLanguage));
    document.documentElement.lang = selectedLanguage;

    import(`moment/locale/${selectedLanguage}`).then(() => {
      moment.locale(selectedLanguage);
      setReload(reload + 1);
    }).catch(() => {});
  }, [selectedLanguage]);

  return <LanguageContext.Provider
    value={{
      t,
      selectedLanguage,
      setSelectedLanguage,
      formatedLanguage,
      DataGridLanguage
    }}
  >
    {children}
  </LanguageContext.Provider> 
}