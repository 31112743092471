import React, { createContext, useContext, useEffect, useState } from 'react';
import { HeaderUI } from './HeaderUI';
import { Sidebar } from './Sidebar/Sidebar';
import { faAngleDoubleUp, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from "@auth0/auth0-react";
import { GlobalContext } from '../../Contexts/GlobalContext/GlobalContext'
import { useLocation } from "react-router-dom";
import { useAPI } from 'src/Hooks/useAPI/useAPI';
import { Environments } from 'src/Environments/Environments';
import { LanguageContext } from 'src/Components/languageContext/LanguageContext';
import { NotificationAlert } from '../NotificationAlert/NotificationAlert';
import { useRefState } from 'src/Hooks/useRefState/useRefState';
import { useSocket } from 'src/Hooks/useSocket/useSocket';

const HeaderContext = createContext();

function HeaderProvider ({children}) {

  const [clickedElement, setClickedElement] = useState(<span id="48753489578387fyhudhsfh"></span>);

  window.onclick = e => {
    setClickedElement(e.target);
  }

  return (
    <HeaderContext.Provider
      value={{
        clickedElement,
        setClickedElement
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

function Header() {

  const location = useLocation();

  const { thisUserFunctions, toggleTheme, darkTheme, token, thisUser } = useContext(GlobalContext);
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);
  const [ sidebarDeploy, setSidebarDeploy ] = useState(false);
  const [ appsOptionsIcon, setAppsOptionsIcon] = useState(faAngleDoubleDown);
  const [ loggedUser, setLoggedUser ] = useState({});
  const { isLoading, user, } = useAuth0();

  const [ notifications, notificationsRef, setNotifications ] = useRefState([]);
  const [ notificationCount, notificationCountRef, setNotificationCount ] = useRefState(0);
  const initialNotifications = useAPI(Environments.toolbox + 'notifications?size=20&pageNo=1', "GET", {}, [location.pathname]);

  const [ notificationToCheck, setNotificationToCheck ] = useState(false);
  const checkedNotification = useAPI({
    url: Environments.toolbox + 'notifications/' + notificationToCheck,
    showAlert: true
  }, 'PUT', { seen: true }, [notificationToCheck], (notificationToCheck));

  const socket = useSocket(Environments.sockets.notifications, { auth: { token } });

  const HeaderAppsOptionToggleColor = `ibisa-apps-option ${(appsOptionsIcon === faAngleDoubleDown) ? "ibisa-apps-option-deployed":""}`;

  const deployProcessOptions = () => {
    document.getElementById("SidebarDropdownProcess").classList.toggle("sidebar-dropdown-deployed");
  }

  const deployAppsOptions = () => {
    document.getElementById("SidebarDropdownApps").classList.toggle("sidebar-dropdown-deployed");
    if (appsOptionsIcon === faAngleDoubleDown) {
      setAppsOptionsIcon(faAngleDoubleUp);
    } else {
      setAppsOptionsIcon(faAngleDoubleDown);
    }
  }

  useEffect(() => {
    if (!initialNotifications.loading && !initialNotifications.error) {
      let newNotifications = [ ...initialNotifications.response.data?.filter(not => not.title != "") ];

      setNotifications(newNotifications);
      setNotificationCount(newNotifications.filter(n => !n.seen).length)
    }
  }, [initialNotifications.loading]);

  useEffect(() => {
    if (!socket.connected) return;
    
    socket.addListener('notification-new', (notification) => {
      if (
        thisUser.authId !== notification?.to &&
        !thisUser.function?.some(f => f === notification?.to)
      ) return;

      setNotifications([ notification, ...notificationsRef.current ]);
      setNotificationCount(notificationCountRef.current + 1);
    });

    socket.addListener('notification-update', (notification) => {
      let newNotifications = [ ...notificationsRef.current ];

      newNotifications.splice(newNotifications.findIndex(n => n._id === notification._id), 1, notification);

      setNotifications(newNotifications);
      setNotificationCount(newNotifications.filter((n) => !n.seen).length);
    });

    socket.addListener('notification-delete', (notificationId) => {
      let newNotifications = [ ...notificationsRef.current ];

      newNotifications.splice(newNotifications.findIndex(n => n._id === notificationId), 1);

      setNotifications(newNotifications);
      setNotificationCount(newNotifications.filter((n) => !n.seen).length);
    });
  }, [ socket.connected ]);

  useEffect(() => {
    if (!checkedNotification.loading && !checkedNotification.error) {
      setNotificationToCheck(false);
    } else if (!checkedNotification.loading && checkedNotification.error) {
      setNotificationToCheck(false);
    }
  }, [ checkedNotification.loading ]);

  useEffect(() => setSidebarDeploy(false), [location]);

  useEffect(() => { if (!isLoading) setLoggedUser(user) }, [isLoading]);

  return (
    <HeaderProvider>
      <Sidebar
        sidebarDeploy={sidebarDeploy}
        setSidebarDeploy={setSidebarDeploy}
        appsOptionsIcon={appsOptionsIcon}
        setAppsOptionsIcon={setAppsOptionsIcon}
        deployAppsOptions={deployAppsOptions}
        deployProcessOptions={deployProcessOptions}
        thisUserFunctions={thisUserFunctions}
        darkTheme={darkTheme}

        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
      <NotificationAlert
        notifications={notifications}
      />
      <HeaderUI
        sidebarDeploy={sidebarDeploy}
        setSidebarDeploy={setSidebarDeploy}
        appsOptionsIcon={appsOptionsIcon}
        setAppsOptionsIcon={setAppsOptionsIcon}
        notifications={notifications}
        deployAppsOptions={deployAppsOptions}
        deployProcessOptions={deployProcessOptions}
        HeaderAppsOptionToggleColor={HeaderAppsOptionToggleColor}
        user={loggedUser}
        thisUserFunctions={thisUserFunctions}
        toggleTheme={toggleTheme}
        darkTheme={darkTheme}
        setNotificationToCheck={setNotificationToCheck}
        notificationCount={notificationCount}
        // reditectToPaper={reditectToPaper}

        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
    </HeaderProvider>
  )
}

export { Header, HeaderContext };