import { useRef, useState } from "react"

export const useRefState = (defaultValue) => {
  const [ state, setState ] = useState(defaultValue);
  const refState = useRef(defaultValue);

  const changeState = value => {
    refState.current = value;
    setState(value);
  }

  return [
    state, refState, changeState
  ];
}