import React from 'react';
import "./Alert.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faBan, faExclamationTriangle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { AlertContext } from 'src/Contexts/AlertContext/AlertContext';

function Alert() {
  const { alertConfig } = React.useContext(AlertContext);
  const [ alertDisplay, setAlertDisplay ] = React.useState(false);
  const [ count, setCount ] = React.useState(0);

  React.useEffect(() => {
    if (count){
      setAlertDisplay(true);
      setTimeout(() => setAlertDisplay(false), 4000)
    }
    setCount(count + 1)
  }, [alertConfig])

  return (
    <div className={
      `alert-container alert-display-${alertDisplay} ${alertConfig.type === "success" ? "alert-success" : alertConfig.type === "danger" ? "alert-danger" : "alert-warning"}`
    }>
      <span className="alert-close-icon" onClick={() => setAlertDisplay(false)}><FontAwesomeIcon icon={faTimes} /></span>
      <span className="alert-icon"><FontAwesomeIcon icon={alertConfig.type === "success" ? faCheckCircle : alertConfig.type === "danger" ? faBan : faExclamationTriangle} /></span>
      <span className="alert-title">{alertConfig.title}</span>
      <span className="alert-message">{alertConfig.message}</span>
    </div>
  )
}

export { Alert }