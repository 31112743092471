const themes = {
  light: [
    {name: "--background-color", value: "#fff"},
    {name: "--background-color-alter", value: "#202124"},
    {name: "--background-color-contrasted", value: "#f5f5f5"},
    {name: "--background-color-contrasted-light", value: "#f9f9f9"},
    {name: "--background-color-contrasted-hover", value: "#e4e4e4"},
    {name: "--background-modeler-color", value: "#fff"},
    {name: "--primary-color", value: "#ff715a"},
    {name: "--secondary-color", value: "#fff"},
    {name: "--primary-border-color", value: "#cecece"},
    {name: "--secondary-border-color", value: "#d5d5d5"},
    {name: "--primary-font-color", value: "#000000"},
    {name: "--secondary-font-color", value: "#585858"},
    {name: "--terciary-font-color", value: "#737373"},
    {name: "--black-btn-color", value: "#000000"},
    {name: "--gray-btn-color", value: "#535353"},
    {name: "--green-btn-color", value: "#29d98c"},
    {name: "--red-btn-color", value: "#ff4b4b"},
    {name: "--blue-btn-color", value: "#056cf2"},
    {name: "--purple-btn-color", value: "#653bbf"},
    {name: "--yellow-btn-color", value: "#deb500"},
    {name: "--orange-btn-color", value: "#ff715a"},
    {name: "--black-btn-color-hover", value: "#000000"},
    {name: "--gray-btn-color-hover", value: "#535353"},
    {name: "--green-btn-color-hover", value: "#29d98c"},
    {name: "--red-btn-color-hover", value: "#ff4b4b"},
    {name: "--blue-btn-color-hover", value: "#056cf2"},
    {name: "--purple-btn-color-hover", value: "#653bbf"},
    {name: "--yellow-btn-color-hover", value: "#deb500"},
    {name: "--orange-btn-color-hover", value: "#ff715a"},
  ],
  dark: [
    {name: "--background-color", value: "#202124"},
    {name: "--background-color-alter", value: "#fff"},
    {name: "--background-color-contrasted", value: "#34363a"},
    {name: "--background-color-contrasted-light", value: "#27292c"},
    {name: "--background-color-contrasted-hover", value: "#46494e"},
    {name: "--background-modeler-color", value: "#6a6a6a"},
    {name: "--primary-color", value: "#ff715a"},
    {name: "--secondary-color", value: "#fff"},
    {name: "--primary-border-color", value: "#565656"},
    {name: "--secondary-border-color", value: "#404040"},
    {name: "--primary-font-color", value: "#ffffff"},
    {name: "--secondary-font-color", value: "#cecece"},
    {name: "--terciary-font-color", value: "#a3a3a3"},
    {name: "--black-btn-color", value: "#000000"},
    {name: "--gray-btn-color", value: "#535353"},
    {name: "--green-btn-color", value: "#29d98c"},
    {name: "--red-btn-color", value: "#ff4b4b"},
    {name: "--blue-btn-color", value: "#056cf2"},
    {name: "--purple-btn-color", value: "#653bbf"},
    {name: "--yellow-btn-color", value: "#deb500"},
    {name: "--orange-btn-color", value: "#ff715a"},
    {name: "--black-btn-color-hover", value: "#000000"},
    {name: "--gray-btn-color-hover", value: "#fff"},
    {name: "--green-btn-color-hover", value: "#29d98c"},
    {name: "--red-btn-color-hover", value: "#ff4b4b"},
    {name: "--blue-btn-color-hover", value: "#056cf2"},
    {name: "--purple-btn-color-hover", value: "#b192f3"},
    {name: "--yellow-btn-color-hover", value: "#deb500"},
    {name: "--orange-btn-color-hover", value: "#ff715a"},
  ]
}

export { themes }