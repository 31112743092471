import React from "react"
import { t } from "i18next"
import { ErrorScreen } from "../../Screens/ErrorScreen/ErrorScreen"

export class ErrorBoundary extends React.Component {

  state = {error: null};

  static getDerivedStateFromError(error) {
    return {error}
  }

  tryAgain = () => {
    window.location.reload();
  };

  render() {
    return this.state.error ? (
      <ErrorScreen message={ this.props.message ?? t('error.haocurridounerrorenlaaplicacion')} componentsToReload={[this.tryAgain]} minified={this.props.minified} />
    ) : (
      this.props.children
    )
  }
}