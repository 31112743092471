import React from 'react';
import "./Dropdown.css";

import { HeaderContext } from '../Header';

function Dropdown({ children, className, title, id, classNameActive, onClick, onActive, onDisable, width }){

  const { clickedElement } = React.useContext(HeaderContext);
  const [deployDropdown, setDeployDropdown] = React.useState(false);
  const preventFirstRender = React.useRef(0);

  React.useEffect(() => {
    if (clickedElement.id !== id) {
      setDeployDropdown(false)
    }
  }, [clickedElement])
  React.useEffect(() => {
    if (preventFirstRender) {
      if (deployDropdown) {
        if (onActive) {
          onActive()
        }
      } else {
        if (onDisable) {
          onDisable()
        }
      }
    }
    preventFirstRender.current += 1
  }, [deployDropdown])

  return (
    <div className={`dropdown ${deployDropdown ? `dropdown-deployed ${classNameActive ? classNameActive : ""}` : ""} ${className}`}>
      { title }
      <div
        id={id} className="dropdown-click-detector"
        onClick={() => {
          setDeployDropdown(!deployDropdown);
          if( onClick ) onClick();
        }}
      ></div>
      <div className="dropdown-content">
        { children }
      </div>
    </div>
  )
}

export { Dropdown }