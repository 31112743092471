import { io } from "socket.io-client"
import { useEffect, useState } from "react"

export const useSocket = (url, config, condition = true) => {

  const [ socket, setSocket ] = useState(null);

  const [ connected, setConnected ] = useState(false);

  const emit = (eventName, data) => {
    try {
      socket.emit(eventName, data);
      return true;
    } catch (error) {
      console.error(error);
      return false; 
    }
  }

  const joinRoom = (room) => {
    try {
      socket.emit('joinRoom', room);
      return true;
    } catch (error) {
      console.error(error);
      return false; 
    }
  }

  const addListener = (eventName, listener) => {
    socket.removeListener(eventName);
    socket.on(eventName, listener);
  }

  const removeListener = (eventName) => {
    socket.removeListener(eventName);
  }

  const reconnect = async () => {
    if (socket.connected) socket.disconnect();
    socket.connect();
  }

  useEffect(() => {
    if (!condition) return;

    const newSocket = io(url, {
      reconnection: true,
      ...config
    });

    setSocket(newSocket);
    setConnected(true);
  }, [ condition ]);

  return {
    socket,
    addListener,
    removeListener,
    emit,
    joinRoom,
    connected,
    reconnect
  }
}